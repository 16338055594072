import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",
    accessKey: "07aeb6d1-92b0-48fc-9fa3-e202bc32d8753a016979-9f0b-4b1d-8027-5575c13bad47",
    streamToken: "29d4ab2d-0fdb-4056-9fae-ae3739a8ae12",
    videoLibraryId: "119349",
    streamCdnUrl: "https://vz-9b52ffca-773.b-cdn.net",
    streamApiKey: "d2076f0f-b32f-43ba-9ea82cdb0e99-50b7-4c31",
    storageApiPassword: "c3ec9b07-7704-4bd2-aceb3e7f8e05-5a70-4beb",
    cdnUrl: "https://hydra-digital-pwa.b-cdn.net",
    devFolder: "/hydra-digital-pwa/",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    socialsIcon: "/img/ui-theme/socials-icon.svg",
    dashboardMp4: "theme/dashboard/4K_dashboard.mp4",

    // top level menu
    assetsMenuOrder: [
        'Residential-Teaser',
        'Restaurant-Teaser',
        'Office-Teaser',
        'Hospital-Teaser',
    ],

    bed1MenuOrder: [
        'videos',
        'virtual-tour',
        'balcony-views',
        'floorplans',
        'range',
        'renders',
        'pdf',
        'socials',
    ],

    residentialAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Residential Teaser'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/HDNk3mS_c3d'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
        socials: [],
        socialsName: [],
    },

    restaurantAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Restaurant Teaser'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/BhFmCxQyh'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
        socials: [],
        socialsName: [],
    },

    officeAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Office Teaser'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/nozvhP3vA'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
        socials: [],
        socialsName: [],
    },

    hospitalAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Hospital Teaser'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/AdifnQUfB'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
        socials: [],
        socialsName: [],
    },
});

export default { theme };